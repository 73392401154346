@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&display=swap");

/* CUSTOM PACKAGE STYLES */
@import "react-phone-number-input/style.css";
@import "react-tooltip/dist/react-tooltip.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "react-input-checkbox/lib/react-input-checkbox.min.css";

/* COMPONENT STYLES */
@import "./components/header.scss";
@import "./components/footer.scss";

/* PAGES STYLES */
@import "./pages/authentication.scss";
@import "./pages/users.scss";
@import "./pages/activity.scss";
@import "./pages/rewards.scss";
@import "./pages/reports.scss";
@import "./pages/campaigns.scss";
@import "./pages/segments.scss";
@import "./pages/dashboard.scss";
@import "./pages/organization-settings.scss";
@import "./pages/coupon-codes.scss";
@import "./pages/agent-detail.scss";
@import "./pages/blogs.scss";
@import "./pages/blog-detail.scss";
@import "./responsive.scss";
@import "./pages/user-details.scss";

body {
  font-family: "Figtree", sans-serif;
  overflow: auto;
  margin: auto;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1400px !important;
}

.container-full-width {
  max-width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.align-center {
  align-items: center !important;
}

.align-end {
  align-items: flex-end !important;
}

.text-center {
  text-align: center !important;
}

.primary-btn {
  width: 100%;
  border-radius: 5px;
  background-color: #445EA0;
  border: 2px solid #445EA0;
  font-size: 14px;
  font-weight: 600;
}

.primary-btn:disabled {
  background-color: #445EA0;
  border-color: #445EA0;
}

.primary-btn:hover {
  color: #445EA0;
  background-color: #fff;
  border: 2px solid #445EA0;
}

.pl-0 {
  padding-left: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.p-relative {
  position: relative !important;
}

/* FORM STYLE */
.form-group {
  margin-bottom: 15px;

  .form-label {
    font-size: 14px;
    font-weight: 600;

    span {
      color: red;
    }
  }

  .form-control {
    position: relative;
    width: 100%;
    border: 2px solid #dbdfea;
    font-size: 14px;
    padding: 8px 12px;
  }

  .form-control.validation-border {
    border: 2px solid red;
  }

  .form-control:focus {
    box-shadow: none;
    border: 2px solid #445EA0;
  }

  .social-control {
    padding-left: 35px;
  }

  .form-control::placeholder {
    font-size: 13px;
  }

  .social-icon {
    position: absolute;
    top: 12px;
    left: 10px;
  }

  .rdw-editor-toolbar {
    border: 2px solid #e5e5e5 !important;
    border-radius: 5px !important;

    .rdw-block-wrapper {
      font-size: 13px !important;

      .rdw-block-dropdown {
        .rdw-dropdown-selectedtext {
          text-decoration: none !important;
          color: #323232 !important;
        }
      }
    }
  }

  .texteditor-control {
    width: 100%;
    min-height: 150px;
    border: 2px solid #dbdfea;
    border-radius: 5px;
    padding: 15px;

    .DraftEditor-root {
      .public-DraftEditorPlaceholder-root {
        font-size: 13px !important;
      }

      .public-DraftEditor-editorContainer {
        font-size: 14px !important;
        font-weight: 600 !important;
        color: #323232 !important;
      }

      .public-DraftStyleDefault-block {
        margin-top: 0px !important;
      }
    }
  }

  .texteditor-control.validation-border {
    border: 2px solid red;
  }

  .checkbox-control {
    margin-right: 30px;

    .form-check-label {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .select-control {
    .custom__control {
      border: 2px solid #dbdfea;

      .custom__placeholder {
        font-size: 14px;
        font-weight: 500;
        color: grey;
      }

      .custom__single-value {
        font-size: 14px;
        font-weight: 600;
        color: #323232;
      }

      .custom__indicators {
        .custom__indicator-separator {
          display: none;
        }
      }
    }

    .custom__menu {
      .custom__option {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .select-control.validation-border {
    .custom__control {
      border: 2px solid red;
    }
  }
}

.datepicker-control {
  width: 100%;
  font-size: 14px;
  border: 2px solid #dbdfea;
  padding: 8px 12px;
  border-radius: 5px;
}

.datepicker-control.validation-border {
  border: 2px solid red;
}

.custom-datepicker-control {
  width: 100%;

  .rc-time-picker-input {
    width: 100%;
    height: auto !important;
    font-size: 14px;
    border: 2px solid #dbdfea;
    padding: 8px 12px;
    border-radius: 5px;
    color: #323232;
    text-transform: uppercase;

    button {
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .rc-time-picker-input::placeholder {
    text-transform: capitalize;
  }

  .rc-time-picker-clear-icon:after {
    display: none;
  }
}

.custom-datepicker-control.validation-border {
  .rc-time-picker-input {
    border: 2px solid red;
  }
}

.textarea-control {
  display: block;
  width: 100%;
  font-size: 14px;
  border: 2px solid #dbdfea;
  padding: 8px 12px;
  border-radius: 5px;
}

.textarea-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.textarea-control.validation-border {
  border: 2px solid red;
}

.datepicker-control:focus {
  outline: none;
}

.phone-control {
  .PhoneInputInput {
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #dbdfea;
    border-radius: 5px;
    padding: 6px 12px;
  }

  .PhoneInputInput::placeholder {
    font-size: 13px;
  }

  .PhoneInputCountry {
    border: 1px solid #dbdfea;
    border-radius: 5px;
    padding: 5px 10px;
  }
}

.main {
  min-height: calc(100vh - 155px);
  position: relative;
}

.banner-background {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  position: absolute;
  top: 0;
  background-color: #323232;
}

.border-btn {
  height: 100%;
  background-color: #fff;
  color: #323232;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #dbdfea;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 10px;
}

.border-btn:hover {
  background-color: #445EA0;
  color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.resp-start {
  justify-content: flex-end;
}

.Toastify__toast-container {
  .Toastify__toast {
    font-family: "Figtree", sans-serif !important;
    font-weight: 600;
  }
}

.custom-badge.offline {
  width: max-content;
  background-color: rgba($color: #445EA0, $alpha: 0.2);
  padding: 3px 10px;
  border-radius: 5px;
  margin-bottom: 10px;

  p {
    font-size: 12px !important;
    font-weight: 600;
    margin-bottom: 0px;
    color: #445EA0 !important;
  }
}

.custom-badge.online {
  width: max-content;
  background-color: rgba($color: #323232, $alpha: 0.2);
  padding: 3px 10px;
  border-radius: 5px;
  margin-bottom: 10px;

  p {
    font-size: 12px !important;
    font-weight: 600;
    margin-bottom: 0px;
    color: #323232 !important;
  }
}

.react-datepicker {
  font-family: "Figtree", sans-serif !important;
}

.delete-user-modal-header {
  h5 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0px;
    color: #323232;
  }
}

.delete-user-modal-body {
  p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #323232;
  }
}

.delete-user-modal-footer {
  .cancel-btn {
    background-color: #fff;
    padding: 0px;
    border: 0px solid transparent;
    color: #323232;
    font-size: 14px;
    font-weight: 600;
  }

  .proceed-btn {
    background-color: red;
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    border-color: red;
  }
}

.rdt_Table {
  font-size: 16px;
  font-weight: 700;
}

.rdw-dropdown-selectedtext {
  text-decoration: none !important;

  span {
    color: #323232;
  }
}

/* VIEW DETAILS MODAL */
.view-details-modalheader {
  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
      center/0.6em auto no-repeat;
  }
}

.status-radio {
  .form-check-input {
    height: 15px;
    width: 15px;
  }

  .form-check-label {
    font-size: 14px;
    font-weight: 600;
  }
}

.custom-select__control {
  border: 2px solid #e5e5e5;

  .custom-select__value-container {
    .custom-select__placeholder {
      font-size: 13px;
      font-weight: 400;
      color: grey;
    }

    .custom-select__single-value {
      font-weight: 500;
      font-size: 13px;
      color: #323232;
    }
  }

  .custom-select__indicators {
    .custom-select__indicator-separator {
      display: none;
    }

    .custom-select__indicator {
      padding-left: 0px;

      svg {
        fill: #323232;
        height: 16px;
        width: 16px;
      }
    }
  }

  .custom-select__multi-value {
    background-color: #e5e5e5;
    padding: 3px 8px;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 600;

    .custom-select_multi-value_remove:hover {
      background-color: transparent;

      svg {
        fill: #445EA0;
      }
    }
  }
}

.custom-select__control:hover {
  border: 2px solid #e5e5e5;
}

.custom-select__control.validation-border {
  border: 2px solid red;
}

.custom-select__control--is-focused {
  border: 2px solid #e5e5e5;
  box-shadow: none;
}

.custom-select__menu {
  z-index: 10;

  .custom-select__menu-list {
    padding: 5px;

    .custom-select__option {
      font-size: 13px;
      font-weight: 500;
      border-radius: 5px;
      cursor: pointer;
    }

    .custom-select__menuu-notice {
      font-size: 14px;
      font-weight: 600;
    }

    .custom-select__option--is-selected {
      background-color: #445EA0;
      color: #fff;
    }

    .custom-select__option--is-focused {
      background-color: transparent;
      color: #323232;
    }

    .custom-select__option:hover {
      background-color: rgba($color: #445EA0, $alpha: 0.1);
      color: #445EA0;
    }
  }
}
.react-select {
  width: 180px !important;
  white-space: nowrap;
}
