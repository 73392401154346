.add-agent {
  .bg-blue-banner {
    background-color: #323232;
    height: 250px;
    width: 100%;
    position: absolute;
    z-index: -1;
  }

  .page-head {
    padding-top: 40px;
    padding-bottom: 40px;

    h1 {
      font-size: 30px;
      font-weight: 700;
      color: #fff;
    }

    p {
      margin-bottom: 0px;
      color: #888b9d;
      font-weight: 600;
    }

    .resp-start {
      justify-content: flex-end;
    }

    .import-btn {
      display: flex;
      align-items: center;
      padding: 8px 25px;
      background-color: #fff;
      font-size: 14px;
      color: #323232;
      font-weight: 600;
      border: 0px solid #dbdfea;

      .btn-icon {
        margin-right: 10px;
      }
    }

    .add-btn {
      background-color: #fff;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0px solid transparent;
      color: #323232;
      font-size: 14px;
      font-weight: 600;

      svg {
        margin-right: 10px;
      }
    }
  }
  .view-profile-btn {
    width: 100%;
    background-color: #445EA0;
    border: 2px solid #445EA0;
    margin-top: 12px;
    font-size: 13px;
    color: #fff;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
  }

  .contact-btn {
    width: 100%;
    background-color: #f5f5f5;
    border: 2px solid #e5e5e5;
    margin-top: 12px;
    font-size: 13px;
    color: #323232;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
  }
  .blogs-pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    list-style-type: none;
    justify-content: center;

    .previous,
    .next {
      background-color: #fff;
      border-radius: 50%;
      padding: 4px 10px;
      border: 1px solid #e5e5e5;

      a {
        text-decoration: none;
        color: #323232;
      }
    }
    .blog-pagination-btn {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
    }

    li {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      border: 1px solid #e5e5e5;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      cursor: pointer;

      a {
        font-size: 14px;
        font-weight: 600;
      }
    }

    li:hover {
      background-color: #445EA0;

      a {
        color: #fff;
      }
    }
    .active {
      background-color: #445EA0;

      a {
        color: #fff;
      }
    }
  }

  .agents-list {
    margin-bottom: 40px;

    .list-card {
      .list-cardheader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        background-color: #fff;
        border-bottom: 1px solid #dbdfea;

        p {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0px;
        }

        .left-header {
          display: flex;
          align-items: center;

          .react-select {
            width: 150px;

            .action-select__control {
              font-size: 14px;
              border: 1px solid #dbdfea;

              .action-select__indicator-separator {
                display: none;
              }

              .action-select__placeholder {
                font-size: 13px;
              }
            }

            .action-select__menu {
              .action-select__menu-list {
                .action-select__option {
                  font-size: 13px;
                  font-weight: 400;
                  color: #323232;
                  background-color: #fff;
                }
              }
            }
          }
        }

        .right-header {
          display: flex;
          align-items: center;

          .form-control {
            position: relative;
            width: 250px;
            border: 1px solid #dbdfea;
            font-size: 14px;
            padding: 8px 12px;
          }

          .form-control::placeholder {
            font-size: 13px;
          }
        }
      }

      .list-cardbody {
        .agent-card {
          display: flex;
          align-items: center;
          white-space: nowrap;
          width: 100%;
          background-color: #fff;
          border-radius: 5px;
          border: 1px solid #e5e5e5;

          .agent-profile-image {
            padding: 10px;

            img {
              width: 120px;
              height: 120px;
              border-radius: 50%;
              object-fit: cover;
              padding: 5px;
              border: 2px solid #e5e5e5;
            }
          }

          .image-loading {
            width: 130px;
            height: 130px;
            padding: 5px;
          }

          .agent-details {
            margin-left: 15px;
            margin-right: 15px;
            width: 100%;

            h5 {
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 0px;
              color: #323232;
            }

            .telephone {
              margin-top: 12px;
              text-decoration: none;

              p {
                margin-left: 5px;
                margin-bottom: 0px;
                font-size: 14px;
                font-weight: 500;
                color: #323232;
              }
            }

            .phone-loading {
              width: 70px;
              height: 18px;
              margin-left: 5px;
            }

            .telephone:hover {
              svg {
                fill: #445EA0;
              }

              p {
                color: #445EA0;
              }
            }

            .dre-number {
              margin-top: 12px;
              margin-left: 10px;
              text-decoration: none;

              p {
                margin-left: 5px;
                margin-bottom: 0px;
                font-size: 14px;
                font-weight: 500;
                color: #323232;
              }
            }

            .re-number-loading {
              width: 100px;
              height: 18px;
              margin-left: 5px;
            }

            .engagement-btns {
              gap: 10px;
            }

            .view-profile-btn {
              width: 100%;
              background-color: #445EA0;
              border: 2px solid #445EA0;
              margin-top: 12px;
              font-size: 13px;
              color: #fff;
              font-weight: 600;
              transition: background-color 0.3s ease-in-out;
            }

            .contact-btn {
              width: 100%;
              background-color: #f5f5f5;
              border: 2px solid #e5e5e5;
              margin-top: 12px;
              font-size: 13px;
              color: #323232;
              font-weight: 600;
              transition: background-color 0.3s ease-in-out;
            }
          }
        }
      }

      .list-cardfooter {
        background-color: #fff;
        border-top: 0px solid transparent;

        .agents-pagination {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;

          li {
            border: 1px solid #e5e5e5;
            border-radius: 50%;
            height: 35px;
            width: 35px;
            list-style-type: none;
            margin: 0px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.add-agent-modal-header {
  h5 {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
  }
}

.add-agent-modal-body {
  .profile-img-agent {
    display: flex;
    align-items: center;

    .custom-file-input-css {
      position: relative;
    }

    .img-selection-box {
      height: 130px;
      width: 130px;
      cursor: pointer;
      border-radius: 5px;
      border: 2px dashed #e5e5e5;
      background-color: #f5f5f5;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 9;

      svg {
        margin-bottom: 10px;
      }

      p {
        text-align: center;
        font-size: 13px;
        font-weight: 600;
        color: #323232;
        margin-bottom: 0px;
      }
    }

    .image-control {
      position: absolute;
      width: 130px;
      height: 130px;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0;
    }

    .uploaded-image {
      height: 130px;
      width: 130px;
      border-radius: 5px;
      border: 2px dashed #e5e5e5;
      padding: 5px;
      margin-left: 10px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }

      .action-btns {
        position: absolute;
        top: -10px;
        right: -10px;

        .edit-btn {
          padding: 0px;
          border-radius: 50%;
          background-color: #fff;
          border: 2px solid #445EA0;
          padding: 2px 6px;
          margin-right: 5px;
        }

        .remove-btn {
          padding: 0px;
          border-radius: 50%;
          background-color: #fff;
          border: 2px solid #445EA0;
          padding: 2px 6px;
        }
      }
    }
  }

  .agent-profile-img {
    display: flex;
    align-items: center;

    .image-control {
      position: absolute;
      width: 130px;
      height: 130px;
      top: 0;
      left: 0;
      z-index: 0;
      opacity: 0;
    }

    .img-selection-box {
      height: 130px;
      width: 130px;
      cursor: pointer;
      border-radius: 5px;
      border: 2px dashed #e5e5e5;
      background-color: #f5f5f5;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      svg {
        margin-bottom: 10px;
      }

      p {
        text-align: center;
        font-size: 13px;
        font-weight: 600;
        color: #323232;
        margin-bottom: 0px;
      }
    }

    .uploaded-image {
      height: 130px;
      width: 130px;
      border-radius: 5px;
      border: 2px dashed #e5e5e5;
      padding: 5px;
      margin-left: 10px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }

      .action-btns {
        position: absolute;
        top: -10px;
        right: -10px;

        .edit-btn {
          padding: 0px;
          border-radius: 50%;
          background-color: #fff;
          border: 2px solid #445EA0;
          padding: 2px 6px;
          margin-right: 5px;
        }

        .remove-btn {
          padding: 0px;
          border-radius: 50%;
          background-color: #fff;
          border: 2px solid #445EA0;
          padding: 2px 6px;
        }
      }
    }
  }
}

.add-user-modal-footer {
  .cancel-btn {
    background-color: #fff;
    padding: 0px;
    border: 0px solid transparent;
    color: #323232;
    font-size: 14px;
    font-weight: 600;
  }

  .proceed-btn {
    background-color: #445EA0;
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    border-color: #445EA0;
  }
}
